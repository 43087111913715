import { Fragment } from "react";
import { Box, styled, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { CLIENT_PAGES, DASHBOARD_PAGES } from "../routes/paths";

const TitleContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));
interface Props {
  disableLink?: boolean,
  title?: String,
  sx? : SxProps,
};

function Logo({ disableLink = false, title = "", sx = {} }: Props) {
  const connectedUser = useAppSelector(state => state.auth.user);

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src="/images/logo.png" alt="Logo" width="100%" height="100%" />
      { title !== "" &&
        <TitleContainer>
          <Typography variant="h4">
            {title}
          </Typography>
      </TitleContainer>
      }
    </Box>
  );

  if (disableLink) {
    return <Fragment>{logo}</Fragment>;
  }

  return <Link to={connectedUser ? DASHBOARD_PAGES.dashboard : CLIENT_PAGES.signIn}>{logo}</Link>;
}

export default Logo;
