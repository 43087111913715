import { MenuItem, Select, SelectChangeEvent, styled, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

const TableFilterContainer = styled("div")(()  =>  ({
    width: "100%",
    minWidth: "400px",
    display: "flex",
    alignItems: "center"
}));
const SearchTextField = styled(TextField)(() =>({
    minWidth: "300px",
}));
interface Props {
    headElements: Array<{name: string, regularName: string, transform?: Function}>,
    data: Array<any>,
    filtredDataChange: Function
};

function TableFilter({headElements, data, filtredDataChange}: Props) {
    const [selectedSearchType, setSelectedSearchType] = useState("Filtre");
    const [search, setSearch] = useState("");
  
    const onSearchTypeChange = (event: SelectChangeEvent) => {
        setSelectedSearchType(event.target.value);
        setSearch("");
        filtredDataChange(data);
    };
    const onSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
        filterData(event.target.value);
    };

    const filterData = (search: string) => {
        const searcher = headElements.find(headElement  => headElement.regularName === selectedSearchType);
        let newData = [];
        if(search === "") {
            newData = data;
            filtredDataChange(data);
        }
        else {
            if(searcher){
                newData = data.filter(one => {
                    if(searcher.transform)
                        return searcher.transform(one[searcher.name]).toString().includes(search);
                    else
                        return one[searcher.name].toString().includes(search);
                });
                filtredDataChange(newData); 
            }
            else {
                console.log("default filter");
                // newData = data.filter(one => headElements.map(headElement => one[headElement.name].toString().includes(search)));
            }
        }
    }

    useEffect(() => {
        filtredDataChange(data);
        // eslint-disable-next-line 
    },[data]);

    return (
        <TableFilterContainer>
            <Select sx={{minWidth:"300px", margin:"10px"}} value={selectedSearchType} onChange={onSearchTypeChange} size="small">
                <MenuItem value="Filtre">Filtre</MenuItem>
                {headElements.map((headElement, index) =>
                    <MenuItem key={index} value={headElement.regularName}>{headElement.regularName}</MenuItem>
                )}
            </Select>
            <SearchTextField size="small" value={search} onChange={onSearchChange}/>
        </TableFilterContainer>
    );
}

export default TableFilter;