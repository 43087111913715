import { styled, SxProps } from "@mui/material";
import { Fragment, useState } from "react";
import TableFilter from "./TableFilter";
import TableHead from "./TableHeader";
import TableRow from "./TableRow";

const TableContainer = styled("table")(() => ({
    width: "calc(100% - 20px)",
    minWidth: "400px",
    margin: "10px",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
}));
const TableBodyContainer = styled("tbody")(() => ({
}));
interface Props {
    data: Array<any>,
    headElements: Array<{name: string, regularName: string, transform?: Function}>,
    tableSx?: SxProps,
    headSx?: SxProps,
    rowSx?: SxProps,
    cellSx?: SxProps,
    id?: string,
    actions?: Object
};

function Table({data, headElements, id, tableSx = {}, rowSx = {}, headSx = {}, cellSx = {}, actions = []}: Props) {
    const [filtredData, setFiltredData] = useState<Array<any>>([]);

    const getFiltredData = (dataFiltred: Array<any>) => setFiltredData(dataFiltred);
    
    return (
        <Fragment>
            <TableFilter headElements={headElements} data={data} filtredDataChange={getFiltredData}/>
            <TableContainer sx={{...tableSx}}>
                <TableHead headElements={headElements} actions={actions} headSx={headSx} cellSx={cellSx}/>
                <TableBodyContainer>
                    {filtredData.map((row, index) => <TableRow key={index} headElements={headElements} rowElements={row} line={index+1} id={id} actions={actions} rowSx={rowSx} cellSx={cellSx}/>)}
                </TableBodyContainer>
            </TableContainer>
        </Fragment>
    );
}

export default Table;
