import { styled, SxProps } from "@mui/material";

const TableHeadContainer = styled("thead")(() => ({
}));
const TableHeadRow = styled("tr")(() => ({
    height: "56px",
}));
const TableHeadCell = styled("th")(() => ({
    textAlign: "left"
}));
interface Props {
    headElements: Array<{name: string, regularName: string}>,
    headSx? : SxProps,
    cellSx?: SxProps,
    actions?: Object
}

function TableHead({headElements, headSx = {}, cellSx = {}, actions = []}: Props) {
    return (
        <TableHeadContainer>
            <TableHeadRow sx={{...headSx}}>
                <TableHeadCell sx={{...cellSx}}>N°</TableHeadCell>
                {headElements.map((headElement, index) => <TableHeadCell key={index} sx={{...cellSx}}>{headElement.regularName}</TableHeadCell>)}
                {Object.entries(actions).length > 0 && <TableHeadCell sx={{...cellSx}}></TableHeadCell>}
            </TableHeadRow>
        </TableHeadContainer>
    );
}

export default TableHead;