import { styled, SxProps } from "@mui/material";
import TableActions from "./TableActions";

const TableRowContainer = styled("tr")(() => ({
    height: "56px",
    boxShadow: "0px -3px rgba(0, 0, 0, 0.1)",
}));
const TableRowCell = styled("td")(() => ({
    textAlign: "left"
}));
interface Props {
    headElements: Array<{name: string, regularName: string, transform?: Function}>,
    rowElements: Object,
    line: number,
    rowSx? : SxProps,
    cellSx?: SxProps,
    id?: string,
    actions?: Object,
};

function TableRow({headElements, rowElements, line, rowSx = {}, cellSx = {}, id, actions = []}: Props) {

    const getDataByHeadElement = (headElement: string, transform?: Function) => {
        if(transform)
            return transform(Object.entries(rowElements).find(rowElement => rowElement[0] === headElement)?.[1]);
        return Object.entries(rowElements).find(rowElement => rowElement[0] === headElement)?.[1];
    }

    return (
        <TableRowContainer sx={{...rowSx}}>
            <TableRowCell sx={{...cellSx}}>{line}</TableRowCell>
            {headElements.map((headElement, index) => <TableRowCell key={index} sx={{...cellSx}}>{getDataByHeadElement(headElement.name, headElement.transform)}</TableRowCell>)}
            {Object.entries(actions).length > 0 && <TableRowCell sx={{...cellSx}}><TableActions id={getDataByHeadElement(id!)} actions={actions}/></TableRowCell>}
        </TableRowContainer>
    );
}

export default TableRow;