import { UploadResult } from "firebase/storage";
import * as storage from "./storage";

const storageRef = storage.ref(storage.storage);

export const sendFile = (file:string, path: string, format: "base64" | "base64url" | "data_url"): PromiseLike<UploadResult> => {
    const fileRef = storage.ref(storageRef, path);
    return storage.uploadString(fileRef, file, format).then();
}

export const getFileUrl = (path: string): Promise<string> => {
    const fileRef = storage.ref(storageRef, path);
    return storage.getDownloadURL(fileRef)
}
