import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import MyDrawer from "../components/MyDrawer/MyDrawer";

export default function DashboardLayout() {
  return (
    <Fragment>
        <Header/>
        <MyDrawer/>
        <Outlet />
    </Fragment>
  )
}
