import { Box, Drawer, List, Toolbar } from "@mui/material";
import { AdminMenuItems } from "../../common/MenuConfig";
import MyDrawerItem from "./MyDrawerItem";

function MyDrawer() {
    return (
        <Drawer variant="permanent" sx={{ width: 200, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: 200, boxSizing: "border-box" }}}>
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {AdminMenuItems.map((menu, index) => (
                <MyDrawerItem key={index} title={menu.title} path={menu.path}/>
              ))}
            </List>
          </Box>
        </Drawer>
    );
}

export default MyDrawer;