import { ListItem, ListItemText, styled } from "@mui/material";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

const CustomLink = styled(NavLink)(() => ({
    width: "calc(100%)",
    height: "100%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    borderRight: "3px solid #E0E0E0",
    fontSize: 20,
    color: "black",
    "&:hover": {
        borderRight: "3px solid #78BAFE",
    },
}));
interface Props {
    title: string,
    path: string,
}

function MyDrawerItem({title, path}: Props) {
    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <ListItem key={title} disablePadding sx={{borderBottom: "1px solid #E0E0E0"}}>
            <CustomLink to={path} sx={{height: "70px"}} style={{borderRight: match ? "3px solid #78BAFE" : ""}}>
                <ListItemText primary={title} />
            </CustomLink>
        </ListItem>
    );
}

export default MyDrawerItem;