import { Logout } from "@mui/icons-material";
import { AppBar, Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/slices/auth";
import { useAppDispatch } from "../../redux/store";
import Logo from "../Logo";

function Header() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event:any) => {
      setAnchorEl(null);
      if(event.target.innerText === "Déconnecter")
        dispatch(signOut()).unwrap().then((res) => {
            navigate("/");
        }).catch(error => {
            console.log(error);
        });
    };

    return (
        <AppBar position="fixed" sx={{height: "80px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Logo sx={{width: 70, height: 70, marginLeft: "10px"}}/>
            <Box sx={{marginRight: "10px"}}>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <Tooltip title="Ménu">
                        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                            <Avatar sx={{ width: 32, height: 32 }}/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} PaperProps={{
                    elevation: 0,
                    sx: { overflow: "visible", filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", mt: 1.5, 
                    "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1}, 
                    "&:before": { content: '""', display: "block", position: "absolute", top: 0, right: 14, width: 10, height: 10, bgcolor: "background.paper", transform: "translateY(-50%) rotate(45deg)", zIndex: 0}}}}
                    transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Déconnecter
                    </MenuItem>
                </Menu>
            </Box>
        </AppBar>
    );
}

export default Header;