import { MoreVert } from "@mui/icons-material";
import { ClickAwayListener, Container, IconButton, styled, Typography } from "@mui/material";
import { useState } from "react";

const TableActionsContainer = styled(Container)(() => ({
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
}));
const MenuContainer = styled(IconButton)(() => ({
}));
const MenuItemContainer = styled("div")(() => ({
    position: "absolute",
    top: "50px",
    right: "5px",
    minWidth: "200px",
    minHeight: "50px",
    display: "none",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.7)",
    backdropFilter: "blur(20px)",
    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    zIndex: 5
}));
const MenuItem = styled("div")(() => ({
    width: "190px",
    height: "50px",
    marginTop: "5px",
    marginBottom: "5px",
    textDecoration: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '&:hover': {
        backgroundColor: 'gray',
    },
}));
interface Props {
    actions: Object
    id: string,
}

function TableActions({id, actions}: Props) {
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    const toggleMenu = () => {
        setMenuIsVisible(!menuIsVisible);
    }

    const onClickAway = () => {
        setMenuIsVisible(false);
    }

    const doAction = (action: Function, id: string) => {
        setMenuIsVisible(false);
        action(id);
    }
    
    return (
        <ClickAwayListener onClickAway={onClickAway} disableReactTree={false}>
            <TableActionsContainer>
                <MenuContainer onClick={toggleMenu} children={<MoreVert sx={{fontSize: "35px"}}/>}/>
                <MenuItemContainer sx={{display: menuIsVisible ? "flex" : "none"}}>
                    {
                        Object.entries(actions).map((action, index) => <MenuItem key={index} onClick={() => doAction(action[1], id)}><Typography>{action[0]}</Typography></MenuItem>)
                    }
                </MenuItemContainer>
            </TableActionsContainer>
        </ClickAwayListener>
    );
}

export default TableActions;