import { AddCircle, Delete, Redo, Visibility } from "@mui/icons-material";
import { IconButton, styled, SxProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const ImageSelectorContainer = styled("div")(()=>({
    width: "100px",
    height: "100px",
}));
const ImageTitle = styled(Typography)(()=>({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));
const ImageContainer = styled("div")(()=>({
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #1976D2"
}));
const Image = styled("img")(()=>({
    width: "100%",
    height: "100%",
}));
const ImageSelectorController = styled("div")(()=>({
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, .3)",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .45)",
    }
}));
interface Props {
    sx?: SxProps,
    title?: string,
    image?: string,
    onChange?: Function
}

function ImageSelector({sx, title, image, onChange = () => {}}: Props) {
    const [selectedImage, setSelectedImage] = useState<any>("");

    const removeImage = () => {
        setSelectedImage("");
    }
    
    const redoImage = () => {
        setSelectedImage(image);
    }

    useEffect(() => {
        setSelectedImage(image);
    },[image]);

    const selectImage = () => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = () => {
            if(input.files?.length === 1 && input.files[0].type.includes("image/")) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    let image = e.target?.result;
                    setSelectedImage(image);
                    onChange(image);
                }
                reader.readAsDataURL(input.files[0]);
            }else
             console.log("Mauvais format");
        }
        input.click();
    };

    return (
        <ImageSelectorContainer sx={{...sx}}>
            {title && <ImageTitle variant="body1">{title}</ImageTitle>}
            <ImageContainer>
                {!selectedImage &&
                    <ImageSelectorController>
                        <IconButton onClick={selectImage}><AddCircle color="primary"/></IconButton>
                        <IconButton onClick={redoImage}><Redo color="primary"/></IconButton>
                    </ImageSelectorController>
                }
                {selectedImage && <Image src={selectedImage} alt=""/>}
                {selectedImage &&
                    <ImageSelectorController>
                        <IconButton><Visibility color="primary"/></IconButton>
                        <IconButton onClick={removeImage}><Delete color="primary"/></IconButton>
                    </ImageSelectorController>
                }
            </ImageContainer>
        </ImageSelectorContainer>
    );
}

export default ImageSelector;